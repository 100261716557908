<script
    lang="ts"
    setup
>
    type Props = {
        file: string
        title: string
        text: string
    }

    const props = defineProps<Props>()

    const { mobile } = useWindowSize()

    const tooltipProps = computed<any>(() => ({
        direction: mobile.value ? 'down' : 'right',
        offsetX: mobile.value ? '-40' : undefined,
        tailOffsetX: mobile.value ? '-30' : undefined,
    }))
</script>

<template>
    <AppTooltip
        v-bind="tooltipProps"
        width="240"
    >
        <template #activator="{ open, close }">
            <div
                class="
                    flex
                    items-center
                    justify-center
                    min-w-[20px]
                    min-h-[20px]
                    w-[20px]
                    h-[20px]
                    text-[14px]
                    font-bold
                    text-secondary-text
                    bg-[rgba(220,224,227,0.3)]
                    rounded-full
                    cursor-pointer
                    hover:bg-secondary-hover
                    hover:text-gray-600
                "
                @mouseenter.passive="open()"
                @mouseleave.passive="close()"
            >
                ?
            </div>
        </template>

        <div class="flex flex-col gap-2 overflow-hidden">
            <AppPublicImage
                :file="props.file"
                class="w-[208px] h-[208px]"
            />

            <div class="text-wrap mt-2 font-medium leading-[130%]">
                {{ $t(props.title) }}
            </div>

            <div class="text-wrap text-[14px] leading-[130%]">
                {{ $t(props.text) }}
            </div>
        </div>
    </AppTooltip>
</template>
