<script
    lang="ts"
    setup
>
    import type { StyleValue } from 'vue-demi'

    type Props = {
        modelValue: any
        name: string
        error?: string
        type?: string
        label?: string
        placeholder?: string
        disabled?: boolean
        loading?: boolean
        readonly?: boolean
        autocomplete?: boolean
        withoutErrorText?: boolean
        inputStyle?: StyleValue
    }

    type Emit = {
        (event: 'update:modelValue', value: any): void
        (event: 'update:error', value: string): void
    }

    const props = withDefaults(defineProps<Props>(), {
        error: '',
        type: 'text',
        label: undefined,
        placeholder: undefined,
        disabled: false,
        loading: false,
        readonly: false,
        autocomplete: false,
        withoutErrorText: false,
        inputStyle: undefined,
    })

    const emit = defineEmits<Emit>()

    const inputModel = computed({
        get() {
            return props.modelValue
        },
        set(value) {
            if (props.error) {
                emit('update:error', '')
            }

            emit('update:modelValue', value)
        },
    })
</script>

<template>
    <AppFormFieldContainer
        :disabled="props.disabled"
        :loading="props.loading"
        :error-message="props.error"
        :without-error-text="props.withoutErrorText"
    >
        <template #default="{ inputClass, labelClass }">
            <input
                ref="inputRef"
                key="input"
                v-model="inputModel"
                :name="props.name"
                :type="props.type"
                :disabled="props.disabled"
                :readonly="props.readonly"
                :placeholder="props.placeholder || ' '"
                :class="inputClass"
                :autocomplete="props.autocomplete ? 'on' : 'off'"
                :style="props.inputStyle"
            />

            <span
                v-if="props.label"
                key="label"
                :class="labelClass"
            >
                {{ props.label }}
            </span>
        </template>

        <template
            v-if="$slots['prefix']"
            #prefix
        >
            <slot name="prefix" />
        </template>

        <template
            v-if="$slots['suffix']"
            #suffix
        >
            <slot name="suffix" />
        </template>

        <template
            v-if="$slots['bottom']"
            #bottom
        >
            <slot name="bottom" />
        </template>
    </AppFormFieldContainer>
</template>
