<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.9393 6.43933L19.0607 8.56065L8.99999 18.6213L3.43933 13.0607L5.56065 10.9393L8.99999 14.3787L16.9393 6.43933Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
